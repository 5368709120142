<template lang="pug">
.overview-container(v-if="contract")
  expansible-card(:title="$t('.contractInfo')" expanded)
    Contract(v-bind="commonBindings")

  files(
    :documents="contract.filesUrls"
    :onUpdate="onUpdate"
    :contractId="contract.id"
  )

  expansible-card(:title="$t('.billingInfo')")
    Billing(v-bind="commonBindings")

  expansible-card(:title="$t('.landlordInfo')")
    Landlord(v-bind="commonBindings")

  expansible-card(:title="$t('.tenantInfo')")
    Tenant(v-bind="commonBindings")

  expansible-card(:title="$t('.propertyInfo')")
    Property(v-bind="commonBindings")
</template>
<script>
import ExpansibleCard from "../expansible-card.vue"
import Contract from "./overview/contract.vue"
import Billing from "./overview/billing.vue"
import Landlord from "./overview/landlord.vue"
import Tenant from "./overview/tenant.vue"
import Property from "./overview/property.vue"

import Files from "./files/index.vue"

export default {
  components: {
    ExpansibleCard,
    Contract,
    Files,
    Billing,
    Landlord,
    Tenant,
    Property
  },

  props: {
    loading: { type: Boolean, required: false },
    contract: { type: Object, required: true }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "admin.contracts.show"
    }
  },

  computed: {
    getContractParam() {
      return this.$route.params.id
    },

    contractPath() {
      return `/admins/contracts/${this.getContractParam}`
    },

    commonBindings() {
      return {
        contract: this.contract,
        contractPath: this.contractPath,
        loading: this.loading,
        onUpdate: this.onUpdate
      }
    }
  },

  methods: {
    onUpdate() {
      this.$emit("updateContract")
    }
  }
}
</script>

<style lang="scss">
.contract-container-desktop {
  .content {
    display: flex;
    width: 100vw;
    overflow: hidden;
  }
}
</style>

<style lang="scss" scoped>
:deep(.inline-section) {
  display: flex;
  justify-content: space-between;
  word-break: break-word;

  .one-column {
    width: 10%;
  }

  .two-columns {
    width: 47%;
  }

  .three-columns {
    width: 30%;
  }

  .four-columns {
    width: 52%;
  }

  .eleven-columns {
    width: 90%;
  }

  .full-size {
    width: 100%;
  }

  .info-title {
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: $second-color-light;
  }

  .info-content {
    font-size: 1.5rem;
    font-family: Lato;

    &.clickable {
      &:hover {
        background-color: darken($white-color, 5%);
      }
    }
  }

  .loading-content {
    background: $loading-gradient;
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
    width: 100%;
    height: 3.5rem;
    margin-bottom: 1.5rem;

    &.observation {
      height: 15rem;
    }
  }

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }

  .edit-container {
    width: 100%;
    display: inline-flex;
    gap: 0.5rem;

    input {
      width: 100%;
      padding: 0px 10px;
      font-family: Lato;
      font-size: 1.5rem;
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 0.3rem;
    }

    .buttons {
      display: flex;
      gap: 0.3rem;

      button {
        padding: 0 10px;
        width: 38px;

        &.close {
          background-color: $red-color;
        }

        .fa {
          font-size: 1.2rem;
          line-height: 32px;
        }
      }

      .button-progress {
        background-image: linear-gradient(90deg, $primary-color 45%, $primary-light 45%);
        background-size: 200%;
        animation: progress 20s ease forwards;
      }

      @keyframes progress {
        from { background-position: right; }
        to { background-position: left; }
      }
    }
  }
}
</style>